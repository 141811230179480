import { useStore } from "vuex";
import useMapAndStore from "@/lib/compositional-logic/useMapAndStore";
import { useRouter } from "vue-router";
import { RepositoryFactory, TemplateRepository } from "../https";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";
import { useI18n } from "vue-i18n";

const { getContractsFullInformation } =
  RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);

const {
  mapLocalBasicInformation,
  mapLocalDetailInformation,
  mapCloudFileToLocalFile,
  mapLocalSignerFromCloudSigner,
  mapLocalSecurityFromCloud,
  mapLocalViewerFromCloudViewer,
  mapLocalNotification
} = useMapAndStore();

export default function useContractFromTemplate(): {
  createContractFromTemplate: (
    templateId: string,
    folder: any
  ) => Promise<void>;
  loadContractFromContractId: (contractId: string) => Promise<void>;
} {
  const store = useStore();
  const router = useRouter();
  const { getAccessCode } = useSignFunctions();
  const { t } = useI18n();

  const createContractFromTemplate = async (
    templateId: string,
    { folderId = "", folderTitle = "" } = {}
  ) => {
    const templateInformation = (await getContractsFullInformation(
      templateId
    )) as any;

    const localBasicContractInformation =
      mapLocalBasicInformation(templateInformation);
    const localDetailContractInformation = mapLocalDetailInformation(
      templateInformation.contractInformation ?? {}
    );
    const localFiles = mapCloudFileToLocalFile(
      templateInformation.contractFiles ?? []
    );
    const localSigners = mapLocalSignerFromCloudSigner(
      templateInformation.contractSigners ?? []
    );
    const localSecurity = mapLocalSecurityFromCloud(templateInformation);
    const localViewers = mapLocalViewerFromCloudViewer(
      templateInformation.contractViewers ?? []
    );

    await Promise.all([
      store.dispatch(
        "template/setTemplateBasicInfo",
        localBasicContractInformation
      ),
      store.dispatch(
        "template/setTemplateDetailInfo",
        localDetailContractInformation
      ),
      store.dispatch("template/setFolder", {
        folderId: folderId,
        folderName: folderTitle,
      }),
      store.dispatch("template/setFiles", localFiles),
      store.dispatch("template/setIsTemplate", false),
      store.dispatch("template/setSigners", localSigners),
      store.dispatch("template/setSecurity", localSecurity),
      store.dispatch("template/setViewers", localViewers),
      store.dispatch("template/setCreatedFromTemplate", templateId),
    ]);

    router.push({ name: "Contract" });
  };

  const loadContractFromContractId = async (contractId: string) => {
    const accessCode = getAccessCode(contractId);
      
    const contractInformation = (await getContractsFullInformation(
      contractId,
      accessCode
    )) as any;

    const localBasicContractInformation =
      mapLocalBasicInformation(contractInformation);
    const localDetailContractInformation = mapLocalDetailInformation(
      contractInformation.contractInformation ?? {}
    );
    const localFiles = mapCloudFileToLocalFile(
      contractInformation.contractFiles ?? []
    );
    const localSigners = mapLocalSignerFromCloudSigner(
      contractInformation.contractSigners ?? []
    );
    const localSecurity = mapLocalSecurityFromCloud(contractInformation);
    const localViewers = mapLocalViewerFromCloudViewer(
      contractInformation.contractViewers ?? []
    );

    const localNotification = mapLocalNotification(
      contractInformation.notification ?? {}
    );

    await Promise.all([
      store.dispatch(
        "template/setTemplateBasicInfo",
        localBasicContractInformation
      ),
      store.dispatch("template/setId", contractId),
      store.dispatch(
        "template/setTemplateDetailInfo",
        localDetailContractInformation
      ),
      store.dispatch("template/setFiles", localFiles),
      store.dispatch("template/setIsTemplate", false),
      store.dispatch("template/setSigners", localSigners),
      store.dispatch("template/setSecurity", localSecurity),
      store.dispatch("template/setViewers", localViewers),
      store.dispatch(
        "template/setMessage",
        contractInformation.message ?? ""
      ),
      store.dispatch("template/setNotification", localNotification),

      store.dispatch(
        "template/setCustomFields",
        contractInformation.contractCustomFields ?? []
      ),
      store.dispatch(
        "template/setStatus",
        contractInformation.status ? contractInformation.status : 0
      ),
      store.dispatch(
        "template/setCountSigned",
        contractInformation.countSigned ? contractInformation.countSigned : 0
      ),
      store.dispatch("template/setFolder", {
        folderId: contractInformation.folderId ?? "",
        folderName: contractInformation.folderTitle ?? t("folder.allFolder"),
      }),
    ]);
  };

  return {
    createContractFromTemplate,
    loadContractFromContractId
  };
}


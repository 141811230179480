
import { defineComponent } from "vue";
import TextButton from "@/components/atomics/TextButton.vue";

export default defineComponent({
  name: "TemplatePicker",
  components: {
    TextButton,
  },
  methods: {
    onClickUpload(): void {
      this.$emit("onChoose");
    },
  },
});

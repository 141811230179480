
import { defineComponent } from "vue";

export default defineComponent({
  name: "PrimaryDoing",
  props: {
    status: {
      type: String,
      default: "doing",
    },
  },
});

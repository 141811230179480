
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
  inject,
} from "vue";
import {
  RepositoryFactory,
  FolderRepository,
  ContractRepository,
} from "@/lib/https";
import FolderPath from "@/components/atomics/FolderPath.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import Popup from "@/components/popups/Popup.vue";
import { mapMutations, useStore } from "vuex";
import { getListFolderFromPath } from "@/lib/utility/common";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "BrowseFolderPopup",
  components: {
    FolderPath,
    FlatButton,
    Popup,
  },
  props: {
    title: { type: String, required: true },
    isTemplateFolder: { type: Boolean, default: false },
    folderId: { type: String, default: "" },
    canChooseRoot: {type: Boolean, default: true},
  },
  emits: ["onChooseFolder"],
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      groupPath: [] as any,
      hoverItemIndex: -1,
      folders: [],
      currentFolder: {} as any,
      isLoading: false,
    });

    const { t } = useI18n();

    const isMobile = inject("isMobile");

    const { getListFolder } =
      RepositoryFactory.getRepository<FolderRepository>(FolderRepository);

    const getListFolderData = async (folderId = "") => {
      state.folders.splice(0);
      state.groupPath.splice(0);
      if (folderId === "received-folder") {
        folderId = "";
      }
      const response = await getListFolder({
        folderId,
        isTemplateFolder: props.isTemplateFolder,
      });
      if (response) {
        state.folders = response.data;
        const paths = getListFolderFromPath(response.pagination.folderPath);
        state.groupPath = [...state.groupPath, ...paths];
        state.currentFolder = paths.pop();
      }
    };

    const clickRootPath = async () => {
      state.groupPath = [];
      state.folders = [];
      await getListFolderData();
    };

    const clickFolderPath = async (folder: any) => {
      state.folders = [];
      const index = state.groupPath.indexOf(folder);
      if (index > -1) {
        state.groupPath.splice(index + 1, state.groupPath.length);
      }
      await getListFolderData(folder.id);
    };

    const resetPopup = () => {
      state.groupPath = [] as any;
      state.hoverItemIndex = -1;
      state.folders = [];
    };

    watch(
      () => store.state.popup.BrowseFolder,
      (val: any) => {
        if (val) {
          resetPopup();
          getListFolderData(props.folderId);
        }
      }
    );

    const onMove = async () => {
        state.currentFolder = state.currentFolder || {id: '', name: t('folder.allFolder')}
      context.emit("onChooseFolder", state.currentFolder);
      store.commit("popup/unsetPopup", "BrowseFolder");
    };

    return {
      ...toRefs(state),
      popupState: computed(() => store.state.popup.BrowseFolder),
      isMobile,
      resetPopup,
      getListFolderData,
      clickRootPath,
      clickFolderPath,
      onMove,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    hidePopup(): void {
      this.unsetPopup("BrowseFolder");
      this.resetPopup();
    },

    onHover(index: number): void {
      this.hoverItemIndex = index;
    },

    onLeave(): void {
      this.hoverItemIndex = -1;
    },

    handleClickFolder(folder: any): void {
      this.groupPath.push(folder);
      this.getListFolderData(folder.id);
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */

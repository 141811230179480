import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7438cc40"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "title-row"
}
const _hoisted_2 = { class: "template-title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "right-area" }
const _hoisted_5 = { class: "full-body" }
const _hoisted_6 = {
  key: 0,
  class: "upload-area-wrapper"
}
const _hoisted_7 = {
  key: 1,
  class: "mobile-area"
}
const _hoisted_8 = {
  key: 0,
  class: "pl-2 pr-2"
}
const _hoisted_9 = {
  key: 1,
  class: "mt-3"
}
const _hoisted_10 = {
  key: 2,
  class: "field-area mt-3"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "input-label mt-2" }
const _hoisted_13 = { class: "folder-row" }
const _hoisted_14 = { class: "input-label mt-2" }
const _hoisted_15 = { class: "section-header mt-5" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "input-label" }
const _hoisted_18 = { class: "input-label" }
const _hoisted_19 = { class: "two-input-row" }
const _hoisted_20 = { class: "haft-line" }
const _hoisted_21 = { class: "input-label" }
const _hoisted_22 = { class: "haft-line" }
const _hoisted_23 = { class: "input-label" }
const _hoisted_24 = { class: "two-input-row" }
const _hoisted_25 = { class: "haft-line" }
const _hoisted_26 = { class: "input-label" }
const _hoisted_27 = { class: "haft-line" }
const _hoisted_28 = { class: "input-label" }
const _hoisted_29 = { class: "date-picker message" }
const _hoisted_30 = { class: "two-input-row" }
const _hoisted_31 = { class: "haft-line" }
const _hoisted_32 = { class: "input-label" }
const _hoisted_33 = { class: "haft-line" }
const _hoisted_34 = { class: "input-label" }
const _hoisted_35 = { class: "input-label" }
const _hoisted_36 = { class: "section-header mt-5" }
const _hoisted_37 = { class: "title" }
const _hoisted_38 = { class: "mt-2 custom-area" }
const _hoisted_39 = {
  key: 0,
  class: "w-full"
}
const _hoisted_40 = { class: "label-input-row" }
const _hoisted_41 = ["src", "onClick"]
const _hoisted_42 = {
  key: 1,
  class: "thin-divider mt-2"
}
const _hoisted_43 = {
  key: 1,
  class: "w-full"
}
const _hoisted_44 = { class: "message text-left" }
const _hoisted_45 = {
  key: 3,
  class: "button-group mt-5"
}
const _hoisted_46 = {
  key: 0,
  class: "bottom-area"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderTitleBar = _resolveComponent("HeaderTitleBar")!
  const _component_BreadScrum = _resolveComponent("BreadScrum")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_StepProgressBar = _resolveComponent("StepProgressBar")!
  const _component_FilePreview = _resolveComponent("FilePreview")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_TemplatePicker = _resolveComponent("TemplatePicker")!
  const _component_text_button = _resolveComponent("text-button")!
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_validation_text_field = _resolveComponent("validation-text-field")!
  const _component_CustomDatepicker = _resolveComponent("CustomDatepicker")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AfterLoginPageLayout = _resolveComponent("AfterLoginPageLayout")!
  const _component_EditBottomNavBar = _resolveComponent("EditBottomNavBar")!
  const _component_ContractTemplateSelectPopup = _resolveComponent("ContractTemplateSelectPopup")!
  const _component_EditCancelPopup = _resolveComponent("EditCancelPopup")!
  const _component_BrowseFolderPopup = _resolveComponent("BrowseFolderPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AfterLoginPageLayout, {
      class: "contract-upload",
      "is-sub-header-menu": true
    }, _createSlots({
      title: _withCtx(() => [
        (!_ctx.isEdit)
          ? (_openBlock(), _createBlock(_component_HeaderTitleBar, {
              key: 0,
              title: _ctx.$t('contractSigner.bannerText'),
              "save-button-text": 
          _ctx.files.length > 0 && _ctx.title != '' ? _ctx.$t('contractSigner.saveButton') : ''
        ,
              "delete-button-text": _ctx.canDelete ? _ctx.$t('contractSigner.deleteButton') : '',
              "on-save": _ctx.onSave,
              "on-delete": _ctx.deleteDraftContract
            }, null, 8, ["title", "save-button-text", "delete-button-text", "on-save", "on-delete"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_BreadScrum, {
                paths: _ctx.breadScrumPaths,
                class: "breadscrum-grey"
              }, null, 8, ["paths"]),
              _createVNode(_component_TextButton, {
                text: _ctx.$t('sign.returnToContract'),
                icon: require('icons/prev-arrow.svg'),
                "is-show-text": !_ctx.isMobile,
                onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPopup('ContractEditCancel')))
              }, null, 8, ["text", "icon", "is-show-text"])
            ]))
      ]),
      bottomRight: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isEdit && !_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_flat_button, {
                key: 0,
                text: _ctx.$t('common.saveChanges'),
                enabled: _ctx.files.length > 0 && _ctx.title != '' && _ctx.stepError < 0,
                class: "w-full mb-5",
                "on-click": _ctx.onEdit
              }, null, 8, ["text", "enabled", "on-click"]))
            : _createCommentVNode("", true),
          _createVNode(_component_StepProgressBar, {
            items: _ctx.progressItems,
            "is-edit": _ctx.isEdit,
            "error-index": 
            _ctx.files.length <= 0 || _ctx.title == ''
              ? 0
              : _ctx.stepError < 0
              ? -1
              : _ctx.stepError
          
          }, null, 8, ["items", "is-edit", "error-index"])
        ])
      ]),
      bottomLeft: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          (!_ctx.isMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: _normalizeClass(["file-upload-area", { 'has-file': _ctx.files.length > 0 }])
                }, [
                  (_ctx.files.length > 0)
                    ? (_openBlock(), _createBlock(_component_FilePreview, {
                        key: 0,
                        class: "half-width",
                        files: _ctx.files,
                        "onUpdate:files": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.files) = $event)),
                        selectedIdx: _ctx.selectedIndex,
                        "onUpdate:selectedIdx": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedIndex) = $event))
                      }, null, 8, ["files", "selectedIdx"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_FileUpload, {
                    class: "half-width",
                    files: _ctx.files,
                    "onUpdate:files": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.files) = $event)),
                    onSelectedAt: _ctx.onSelectedFileAt,
                    onInitialContractName: _ctx.initialContractName,
                    readonly: _ctx.onlyInformation,
                    "file-type": "pdf"
                  }, null, 8, ["files", "onSelectedAt", "onInitialContractName", "readonly"]),
                  (_ctx.files.length <= 0)
                    ? (_openBlock(), _createBlock(_component_TemplatePicker, {
                        key: 1,
                        class: "half-width",
                        onOnChoose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setPopup('SelectTemplate')))
                      }))
                    : _createCommentVNode("", true)
                ], 2)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_FileUpload, {
                  class: "half-width",
                  files: _ctx.files,
                  "onUpdate:files": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.files) = $event)),
                  onSelectedAt: _ctx.onSelectedFileAt,
                  readonly: _ctx.onlyInformation,
                  "file-type": "pdf"
                }, null, 8, ["files", "onSelectedAt", "readonly"]),
                (!_ctx.files.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_text_button, {
                        class: "add-file-btn template-btn",
                        onOnClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setPopup('SelectTemplate'))),
                        text: _ctx.$t('contract.chooseTemplate')
                      }, null, 8, ["text"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (_ctx.files.length > 0)
                        ? (_openBlock(), _createBlock(_component_FilePreview, {
                            key: 0,
                            class: "mobile-preview",
                            files: _ctx.files,
                            "onUpdate:files": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.files) = $event)),
                            selectedIdx: _ctx.selectedIndex,
                            "onUpdate:selectedIdx": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedIndex) = $event)),
                            "is-scale": true,
                            "can-remove": !_ctx.onlyInformation
                          }, null, 8, ["files", "selectedIdx", "can-remove"]))
                        : _createCommentVNode("", true)
                    ]))
              ])),
          (_ctx.files.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("p", {
                  innerHTML: 
              `<span style='color: #D64D10'>${_ctx.$t(
                'common.requiredText'
              )}</span>` +
              ' ' +
              _ctx.$t('contract.templateTitle')
            ,
                  class: "input-label"
                }, null, 8, _hoisted_11),
                _createVNode(_component_ValidationTextField, {
                  rules: "required",
                  message: {
              error_required: _ctx.$t('errors.required'),
            },
                  value: _ctx.title,
                  "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.title) = $event)),
                  "keep-error-space": false,
                  readonly: _ctx.onlyInformation
                }, null, 8, ["message", "value", "readonly"]),
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("common.optionalField") + " " + _ctx.$t("contract.folderPath")), 1),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_ValidationTextField, {
                    value: _ctx.folderName,
                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.folderName) = $event)),
                    placeholder: _ctx.$t('folder.allFolder'),
                    "keep-error-space": false,
                    readonly: true
                  }, null, 8, ["value", "placeholder"]),
                  _createVNode(_component_FlatButton, {
                    text: _ctx.$t('contract.browse'),
                    onOnClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.setPopup('BrowseFolder')))
                  }, null, 8, ["text"])
                ]),
                _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t("common.optionalField") + " " + _ctx.$t("contract.partnerName")), 1),
                _createVNode(_component_ValidationTextField, {
                  value: _ctx.contractPartnerName,
                  "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.contractPartnerName) = $event)),
                  placeholder: _ctx.$t('contract.partnerNameHint'),
                  "keep-error-space": false,
                  readonly: _ctx.onlyInformation
                }, null, 8, ["value", "placeholder", "readonly"]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("contract.advancedOption")), 1),
                  _createElementVNode("img", {
                    class: _normalizeClass([{ open: _ctx.isShowAdvanced }, "mt-1"]),
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.isShowAdvanced = !_ctx.isShowAdvanced)),
                    src: require('icons/icon_dropdown.svg'),
                    alt: "dropdown icon"
                  }, null, 10, _hoisted_16)
                ]),
                _withDirectives(_createElementVNode("div", null, [
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("common.optionalField") +
                  " " +
                  _ctx.$t("contractSummary.contractSummary")), 1),
                    _createVNode(_component_validation_text_field, {
                      "max-length": 200,
                      placeholder: '',
                      value: _ctx.contractOverview,
                      "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.contractOverview) = $event)),
                      "keep-error-space": false
                    }, null, 8, ["value"])
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t("common.optionalField") +
                  " " +
                  _ctx.$t("contractSummary.contractExpiredTime")), 1),
                    _createVNode(_component_CustomDatepicker, {
                      class: "date-picke message",
                      type: "text",
                      placeholder: _ctx.$t('contractSummary.datePickerPlaceholder'),
                      "is-black": true,
                      value: _ctx.contractConclusionDate,
                      "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.contractConclusionDate) = $event))
                    }, null, 8, ["placeholder", "value"])
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("common.optionalField") +
                    " " +
                    _ctx.$t("contractSummary.contractStart")), 1),
                      _createVNode(_component_CustomDatepicker, {
                        class: "date-picker message",
                        type: "text",
                        placeholder: _ctx.$t('contractSummary.datePickerPlaceholder'),
                        "is-black": true,
                        "upper-limit-date": 
                    _ctx.contractEndDate ? new Date(_ctx.contractEndDate) : null
                  ,
                        value: _ctx.contractStartDate,
                        "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.contractStartDate) = $event))
                      }, null, 8, ["placeholder", "upper-limit-date", "value"])
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("common.optionalField") +
                    " " +
                    _ctx.$t("contractSummary.contractEnd")), 1),
                      _createVNode(_component_CustomDatepicker, {
                        class: "date-picker message",
                        type: "text",
                        placeholder: _ctx.$t('contractSummary.datePickerPlaceholder'),
                        "is-black": true,
                        "lower-limit-date": 
                    _ctx.contractStartDate ? new Date(_ctx.contractStartDate) : null
                  ,
                        value: _ctx.contractEndDate,
                        "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.contractEndDate) = $event))
                      }, null, 8, ["placeholder", "lower-limit-date", "value"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t("common.optionalField") +
                    " " +
                    _ctx.$t("contractSummary.cancelExpiredTime")), 1),
                      _createVNode(_component_validation_text_field, {
                        "keep-error-space": false,
                        placeholder: '',
                        value: _ctx.terminationNoticePeriod,
                        "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.terminationNoticePeriod) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t("common.optionalField") +
                    " " +
                    _ctx.$t("contractSummary.autoUpdate")), 1),
                      _createElementVNode("div", _hoisted_29, [
                        _createVNode(_component_Dropdown, {
                          options: _ctx.automaticOptions,
                          "default-option": _ctx.isAutomaticallyUpdated,
                          value: _ctx.isAutomaticallyUpdated,
                          "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.isAutomaticallyUpdated) = $event)),
                          class: "w-full",
                          "place-holder": _ctx.$t('common.selectPlaceHolder')
                        }, null, 8, ["options", "default-option", "value", "place-holder"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t("common.optionalField") +
                    " " +
                    _ctx.$t("contractSummary.controlNumber")), 1),
                      _createVNode(_component_validation_text_field, {
                        "keep-error-space": false,
                        placeholder: '',
                        "max-length": 100,
                        message: {
                    error_format_control_number: _ctx.$t('errors.formatControlNumber'),
                  },
                        rules: "controlNumber",
                        value: _ctx.controlNumber,
                        "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.controlNumber) = $event))
                      }, null, 8, ["message", "value"])
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.$t("common.optionalField") +
                    " " +
                    _ctx.$t("contractSummary.transactionAmount")), 1),
                      _createVNode(_component_validation_text_field, {
                        "keep-error-space": false,
                        placeholder: '',
                        "max-length": 10,
                        value: _ctx.transactionAmount,
                        "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.transactionAmount) = $event)),
                        type: "text",
                        rules: "number",
                        message: {
                    error_format_number: _ctx.$t('errors.formatNumber'),
                  }
                      }, null, 8, ["value", "message"])
                    ])
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.$t("common.optionalField") +
                  " " +
                  _ctx.$t("contractSummary.freeText") +
                  _ctx.$t("contractSummary.numberOfFreeText")), 1),
                    _createVNode(_component_validation_text_field, {
                      value: _ctx.freeText,
                      "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.freeText) = $event)),
                      placeholder: '',
                      "is-multiline": true,
                      class: "multiline-input",
                      "max-length": 2000,
                      "keep-error-space": false
                    }, null, 8, ["value"])
                  ])
                ], 512), [
                  [_vShow, _ctx.isShowAdvanced]
                ]),
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("p", _hoisted_37, _toDisplayString(_ctx.$t("contractSummary.customArea")), 1),
                  _createVNode(_component_text_button, {
                    onOnClick: _ctx.addCustomField,
                    icon: require('icons/add.svg'),
                    text: _ctx.$t('contractSummary.addCustomField'),
                    "is-show-text": !_ctx.isMobile
                  }, null, 8, ["onOnClick", "icon", "text", "is-show-text"])
                ]),
                _createElementVNode("div", _hoisted_38, [
                  (_ctx.customFields.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customFields, (field, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: "mt-2"
                          }, [
                            _createElementVNode("div", _hoisted_40, [
                              _createVNode(_component_validation_text_field, {
                                placeholder: _ctx.$t('contractSummary.labelHint'),
                                "keep-error-space": false,
                                class: "label-input",
                                value: field.title,
                                "onUpdate:value": ($event: any) => ((field.title) = $event)
                              }, null, 8, ["placeholder", "value", "onUpdate:value"]),
                              (!_ctx.isMobile)
                                ? (_openBlock(), _createBlock(_component_validation_text_field, {
                                    key: 0,
                                    placeholder: _ctx.$t('contractSummary.valueHint'),
                                    "keep-error-space": false,
                                    value: field.value,
                                    "onUpdate:value": ($event: any) => ((field.value) = $event),
                                    class: "custom-value-input"
                                  }, null, 8, ["placeholder", "value", "onUpdate:value"]))
                                : _createCommentVNode("", true),
                              _createElementVNode("img", {
                                src: require('icons/remove.svg'),
                                alt: "remove icon",
                                class: "icon",
                                onClick: ($event: any) => (_ctx.removeCustomField(field.index))
                              }, null, 8, _hoisted_41)
                            ]),
                            (_ctx.isMobile)
                              ? (_openBlock(), _createBlock(_component_validation_text_field, {
                                  key: 0,
                                  placeholder: _ctx.$t('contractSummary.valueHint'),
                                  "keep-error-space": false,
                                  value: field.value,
                                  "onUpdate:value": ($event: any) => ((field.value) = $event),
                                  class: "custom-value-input"
                                }, null, 8, ["placeholder", "value", "onUpdate:value"]))
                              : _createCommentVNode("", true),
                            (index != _ctx.customFields.length - 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_42))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_43, [
                        _createElementVNode("p", _hoisted_44, _toDisplayString(_ctx.$t("contractSummary.customMessage")), 1)
                      ]))
                ])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                _createVNode(_component_FlatButton, {
                  class: "transition-button",
                  text: _ctx.$t('common.next'),
                  "right-icon": (_ctx.files.length > 0 && _ctx.title != '') ? require('icons/next-arrow-white.svg') : require('icons/next-arrow-grey.svg'),
                  enabled: _ctx.files.length > 0 && _ctx.title != '',
                  onOnClick: _ctx.next
                }, null, 8, ["text", "right-icon", "enabled", "onOnClick"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 2
    }, [
      (_ctx.isMobile)
        ? {
            name: "topLeft",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("contract.addFile")), 1),
                (_ctx.isMobile)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(1/" + _toDisplayString(_ctx.progressItems.length) + ")", 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1024),
    (_ctx.isMobile && !_ctx.isEdit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
          _createVNode(_component_TextButton, {
            class: "next-button",
            "right-icon": require('icons/next-arrow.svg'),
            text: _ctx.$t('common.next'),
            "disabled-icon": require('icons/next-arrow-grey.svg'),
            enabled: _ctx.files.length > 0 && _ctx.title != '',
            onOnClick: _ctx.next
          }, null, 8, ["right-icon", "text", "disabled-icon", "enabled", "onOnClick"])
        ]))
      : (_ctx.isMobile)
        ? (_openBlock(), _createBlock(_component_EditBottomNavBar, {
            key: 1,
            "on-next": _ctx.files.length > 0 && _ctx.title != '' ? _ctx.next : null,
            "on-edit": _ctx.files.length > 0 && _ctx.title != '' && _ctx.stepError < 0 ? _ctx.onEdit : null
          }, null, 8, ["on-next", "on-edit"]))
        : _createCommentVNode("", true),
    _createVNode(_component_ContractTemplateSelectPopup, {
      onOnSelectFile: _ctx.loadContractFromTemplate,
      "is-mobile": _ctx.isMobile
    }, null, 8, ["onOnSelectFile", "is-mobile"]),
    _createVNode(_component_EditCancelPopup, {
      onOnCancel: _cache[23] || (_cache[23] = ($event: any) => (_ctx.backToFolder(_ctx.folderId, _ctx.folderName, _ctx.contractStatus)))
    }),
    _createVNode(_component_BrowseFolderPopup, {
      title: _ctx.$t('contract.browseFolder'),
      "folder-id": _ctx.folderId || '',
      "can-choose-root": _ctx.accountInfo.role<2,
      onOnChooseFolder: _ctx.onChoose
    }, null, 8, ["title", "folder-id", "can-choose-root", "onOnChooseFolder"])
  ], 64))
}
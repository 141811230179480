import { TemplateInformation } from "@/models/contracts/Information";
import { ContractViewer } from "@/models/contracts/ContractViewer";
import { NotificationInformation } from "@/models/contracts/NotificationInformation";

import { FileInfo } from "@/models/contracts/FileInfo";
import { isEmptyObject } from "../utility/common";
import { ContractSigner } from "@/models/contracts/ContractSigner";

const useMapAndStore = () => {
  const mapLocalBasicInformation = (templateInformation: any) => ({
    title: templateInformation.title,
    contractPartnerName: templateInformation.contractPartnerName ?? "",
  });

  const mapLocalDetailInformation = (detailsInformation: any) =>
    TemplateInformation.fromJson(detailsInformation);

  const mapLocalSignerFromCloudSigner = (signers: any) =>
    signers.map((signer: any) => ContractSigner.fromJson(signer));

  const mapLocalViewerFromCloudViewer = (viewers: any) =>
    viewers.map((viewer: any) => ContractViewer.fromJson(viewer));

  const mapCloudFileToLocalFile = (cloudFiles: any) =>
    cloudFiles.map((file: any) => FileInfo.fromJson(file));

  const mapLocalSecurityFromCloud = (templateInformation: any) => {
    const {
      isXIDRequired = false,
      isOnlyPartnerXIDRequired = false,
      is2FARequired = false,
      isSignerTransferAllowed = false,
      needTimeStamp = false,
    } = templateInformation;
    return {
      isXIDRequired,
      isOnlyPartnerXIDRequired,
      is2FARequired,
      isSignerTransferAllowed,
      needTimeStamp,
    };
  };

  const parseContractDate = (date: any) => {
    return date ? new Date(date) : null;
  };

  const mapLocalNotification = (notification: any) =>
    isEmptyObject(notification)
      ? new NotificationInformation()
      : NotificationInformation.fromJson(notification);

  const createContractInformationFormData = (
    files: Array<any>,
    contractInformation: any
  ) => {
    const formData = new FormData();

    files.forEach((file: any, index: number) => {
      if (!isIncludedUrl(file.path))
        formData.append(`file${index + 1}`, file.data);
    });
    formData.append("data", contractInformation);

    return formData;
  };

  const isIncludedUrl = (url: string) =>
    url.includes(process.env.VUE_APP_API_CLOUD_URL ?? "");

  return {
    mapLocalBasicInformation,
    mapLocalDetailInformation,
    mapLocalSignerFromCloudSigner,
    mapLocalViewerFromCloudViewer,
    mapCloudFileToLocalFile,
    mapLocalSecurityFromCloud,
    parseContractDate,
    mapLocalNotification,

    createContractInformationFormData,
  };
};

export default useMapAndStore;

import useMapAndStore from "@/lib/compositional-logic/useMapAndStore";
import { RepositoryFactory, TemplateRepository } from "@/lib/https";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default function useContract() {
  const { t } = useI18n();
  const router = useRouter();
  const route = useRoute();
  const store = useStore();

  const { createContracts, updateContracts, removeDraftContract } =
    RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);

  const { createContractInformationFormData } = useMapAndStore();

  const editProgressBarState = () => {
    const items = [
      {
        name: t("template.progressAddFile"),
        event: () => router.push({ name: "Contract", query: route.query, params: route.params }),
      },
      {
        name: t("template.progressSigner"),
        event: () =>
          router.push({ name: "ContractSigners", query: route.query, params: route.params }),
      },
      {
        name: t("template.progressVars"),
        event: () => router.push({ name: "ContractVars", query: route.query, params: route.params }),
      },
      {
        name: t("contract.progressConfirm"),
        event: () =>
          router.push({ name: "ContractSummary", query: route.query, params: route.params }),
      },
    ];

    return items;
  };

  const checkingAndNavigation = (contractInformation: any) => {
    if (contractInformation.files.length <= 0)
      router.replace({ name: "Contract", query: route.query});
  };

  //from 0 - 3
  const setError = (stepError: number) => {
    store.dispatch("template/setError", stepError);
  };

  const resetState = () => {
    store.dispatch("template/resetState");
  };

  const backToFolder = (folderId: string, folderTitle: string, status: string) => {
    resetState();
    router.replace({
      name: "Folder",
      query: {
        folderId: folderId,
        folderTitle: folderTitle,
        status: status,
      },
    });
    store.commit("popup/unsetPopup", "ContractEditCancel");
  };

  const saveEditContract = async (
    onBefore: CallableFunction = () => null,
    onSuccess: CallableFunction = onEditSuccess
  ) => {
    await onBefore();

    const contractJson = store.getters["template/getContractJson"] as string;
    const contractData = store.getters["template/getTemplateInfo"];
    const contractInformation = await createContractInformationFormData(
      contractData.files,
      contractJson
    );

    const response = await updateContracts(contractInformation);
    if (response) onSuccess(contractData.folderId, contractData.folderName);
  };

  const onEditSuccess = async (folderId = "", folderTitle = "") => {
    router.replace({
      name: "Folder",
      query: {
        folderId: folderId,
        folderTitle: folderTitle,
      },
      params: {
        withSuccess: t("notification.updateSuccess")
      },
    });
  };

  const saveDraftContract = async (
    onBefore: CallableFunction = () => null,
    onSuccess: CallableFunction = onSaveSuccessfully
  ) => {
    await onBefore();

    const contractJson = store.getters["template/getContractJson"] as string;
    const contractData = store.getters["template/getTemplateInfo"];
    const draftContractInformation = await createContractInformationFormData(
      contractData.files,
      contractJson
    );

    if (contractData.id)
      await updateCraftContract(draftContractInformation, onSuccess);
    else await saveNewDraftContract(draftContractInformation, onSuccess);
  };

  const onSaveSuccessfully = (response: any) => {
    if (response) {
      store.dispatch("template/setId", response.id);
      showSuccessNotification(
        response.title + t("notification.draftCreateSuccess")
      );
    }
  };

  const updateCraftContract = async (
    draftContractInformation: any,
    onSuccess: CallableFunction
  ) => {
    const response = await updateContracts(draftContractInformation);
    onSuccess(response);
  };

  const saveNewDraftContract = async (
    draftContractInformation: any,
    onSuccess: CallableFunction
  ) => {
    const response = await createContracts(draftContractInformation);
    onSuccess(response);
  };

  const deleteDraftContract = async () => {
    const contractInformation = store.getters["template/getTemplateInfo"];
    if (contractInformation.id) await removeDraftContract(contractInformation.id);
    router.push({
      name: "Folder",
      query: {
        folderId: contractInformation.folderId,
      },
    });
    resetState();
  };

  const showSuccessNotification = (text: string) => {
    store.commit("notification/showSuccessNotification", text);
  };

  const showErrorNotification = (text: string) => {
    store.commit("notification/showErrorNotification", text);
  };

  return {
    editProgressBarState,
    setError,
    checkingAndNavigation,
    resetState,
    backToFolder,
    saveEditContract,
    saveDraftContract,
    deleteDraftContract,
    showSuccessNotification,
    showErrorNotification,
  };
}

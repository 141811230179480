import { getFilePathFromUrl, getRelativePath } from "@/lib/utility/stringUtil";
import { FileInput } from "./FileInput";

export class FileInfo {
  id: string;
  name: string;
  type: "pdf" | "docx";
  size?: number;
  data?: any;
  path: string;
  pageNums?: number = 1;
  currentPage?: number = 1;
  inputs: Array<FileInput> = [];
  contractId?: string;
  scale?: number = 1;
  width?: number = 0;
  height?: number = 0;

  constructor(
    id: string,
    contractId: string,
    name: string,
    path: string,
    type: "pdf" | "docx",
    inputs: Array<FileInput>,
    data: any = {},
    size = 0,
    pageNums = 1,
    currentPage = 1,
    scale = 1,
    width = 0,
    height = 0
  ) {
    this.id = id;
    this.contractId = contractId;
    this.name = name;
    this.path = path;
    this.type = type;
    this.inputs = inputs;
    this.size = size;
    this.data = data;
    this.pageNums = pageNums;
    this.currentPage = currentPage;
    this.scale = scale;
    this.width = width;
    this.height = height;
  }

  static fromJson(file: any) {
    const { contractId, fileName, filePath, fileType, id, contractVars } = file;

    const inputs = (contractVars || []).map((e: any) => FileInput.fromJson(e));

    return new FileInfo(
      id,
      contractId,
      fileName,
      getFilePathFromUrl(filePath),
      fileType,
      inputs
    );
  }

  toJson(order: number) {
    const contractVars = this.inputs.map((input: FileInput) =>
      input.toJson(this.scale || 1)
    );

    if (this.isLocalFile()) return this.toJsonLocalFile(order, contractVars);
    else return this.toJsonStoredFile(order, contractVars);
  }

  hasFields() {
    return this.inputs.length !== 0;
  }

  private isLocalFile() {
    return !this.path.includes(process.env.VUE_APP_API_CLOUD_URL as string);
  }

  private toJsonLocalFile(order: number, contractVars: any) {
    return {
      order: order,
      contractVars: contractVars,
      width: Math.round(this.width || 0),
      height: Math.round(this.height || 0),
    };
  }

  private toJsonStoredFile(order: number, contractVars: any) {
    return {
      ...this.toJsonLocalFile(order, contractVars),
      fileName: this.name,
      fileType: this.type,
      filePath: getRelativePath(this.path),
    };
  }
}


import { defineComponent, reactive, toRefs, PropType } from "vue";

interface TabProps {
  name?: string;
  total?: number;
}

export default defineComponent({
  name: "Tabs",

  emits: ["update:currentTabIndex"],

  props: {
    tabTags: { type: Array as PropType<Array<TabProps>>, default: () => [] },
    isPageHeader: { type: Boolean, default: true },
    // to: { type: String, default: "" },
    currentTabIndex: { type: Number, default: 0 },
  },
  setup(_, { emit }) {
    const state = reactive({
      selectedIndex: 0,
    });

    const setCurrentTab = (i: number) => {
      state.selectedIndex = i;
      emit("update:currentTabIndex", i);
    };

    return { ...toRefs(state), setCurrentTab };
  },
});

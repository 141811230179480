
import {
  defineComponent,
  reactive,
  toRefs,
  inject,
  onMounted,
  watch,
  provide,
} from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import FileUpload from "@/components/parts/FileUpload.vue";
import FilePreview from "@/components/parts/FilePreview.vue";
import TemplatePicker from "@/components/parts/TemplatePicker.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import StepProgressBar from "@/components/atomics/StepProgressBar.vue";
import CustomDatepicker from "@/components/atomics/CustomDatepicker.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import ContractTemplateSelectPopup from "@/components/popups/contract/ContractTemplateSelectPopup.vue";
import EditCancelPopup from "@/components/popups/contract/EditCancelPopup.vue";
import HeaderTitleBar from "@/components/parts/HeaderTitleBar.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import EditBottomNavBar from "@/components/organisms/EditBottomNavBar.vue";
import BrowseFolderPopup from "@/components/popups/contract/BrowseFolderPopup.vue";

import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { mapMutations, useStore } from "vuex";
import { RepositoryFactory, TemplateRepository } from "@/lib/https";
import useMapAndStore from "@/lib/compositional-logic/useMapAndStore";
import { buildContractEditBreadScrumPath } from "@/lib/utility/stringUtil";
import useContract from "./contract";
import { truncate } from "@/lib/utility/stringUtil";
import useContractFromTemplate from "@/lib/compositional-logic/useContractFromTemplate";
export default defineComponent({
  name: "Contract",
  components: {
    AfterLoginPageLayout,
    TextButton,
    FlatButton,
    FileUpload,
    TemplatePicker,
    FilePreview,
    ValidationTextField,
    StepProgressBar,
    CustomDatepicker,
    Dropdown,
    ContractTemplateSelectPopup,
    EditCancelPopup,
    HeaderTitleBar,
    BreadScrum,
    EditBottomNavBar,
    BrowseFolderPopup,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const isMobile = inject("isMobile");

    const state = reactive({
      files: [] as any,
      progressItems: [
        { name: t("template.progressAddFile") },
        { name: t("template.progressSigner") },
        { name: t("template.progressVars") },
        { name: t("contract.progressConfirm") },
        { name: t("template.progressDone") },
      ] as any,
      breadScrumPaths: [],
      customFields: [] as any,
      automaticOptions: [
        {
          id: "true",
          name: t("template.updateOption1"),
        },
        {
          id: "false",
          name: t("template.updateOption2"),
        },
      ],
      selectedIndex: 0,
      isShowAdvanced: true,

      stepError: -1,
      folderId: "",
      folderName: "",
      contractStatus: "",
    });

    const editState = reactive({
      isEdit: false,
      onlyInformation: false,
      canDelete: false,
    });

    const contractBasicInfo = reactive({
      id: "",
      title: "",
      contractPartnerName: "",
    });

    const contractDetailInfo = reactive({
      contractOverview: "",
      contractConclusionDate: null,
      contractStartDate: null,
      contractEndDate: null,
      terminationNoticePeriod: "",
      isAutomaticallyUpdated: "null",
      controlNumber: "",
      transactionAmount: 0,
      freeText: "",
    });


    const { removeDraftContract, getContractsFullInformation } =
      RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);

    const {
      mapLocalSignerFromCloudSigner,
      mapLocalViewerFromCloudViewer,
      mapCloudFileToLocalFile,
      mapLocalSecurityFromCloud,
      parseContractDate,
    } = useMapAndStore();

    const { loadContractFromContractId } = useContractFromTemplate();

    const {
      editProgressBarState,
      setError,
      resetState,
      backToFolder,
      saveEditContract,
      saveDraftContract,
      deleteDraftContract,
      showSuccessNotification,
      showErrorNotification,
    } = useContract();

    const storeContractInformation = async () => {
      const customFields = state.customFields.map(
        (field: any, index: number) => ({
          ...field,
          contractId: contractBasicInfo.id || "",
          order: index + 1,
        })
      );

      contractDetailInfo.transactionAmount =
        contractDetailInfo.transactionAmount
          ? parseInt(contractDetailInfo?.transactionAmount.toString(), 10)
          : 0;
      await Promise.all([
        store.dispatch("template/setTemplateBasicInfo", contractBasicInfo),
        store.dispatch("template/setTemplateDetailInfo", contractDetailInfo),
        store.dispatch("template/setFiles", state.files),
        store.dispatch("template/setCustomFields", customFields),
        store.dispatch("template/setIsTemplate", false),
      ]);
    };

    // Draft Contract Section

    const onSave = async () => {
      await saveDraftContract(
        storeContractInformation,
        onSaveSuccessfully
      ).catch((err) => {
        router.replace({
          name: "Folder",
          query: {
            folderId: state.folderId,
          },
          params: {
            withError: err.data.message,
          },
        });
      });
    };

    const onSaveSuccessfully = (response: any) => {
      showSuccessNotification(
        `${truncate(contractBasicInfo.title)} ${t(
          "notification.draftCreateSuccess"
        )}`
      );
      store.dispatch("template/setId", response.id || "");
      contractBasicInfo.id = response.id || "";
    };

    //Choose Folder for contract
    const onChoose = async (folder: any) => {
      state.folderId = folder.id;
      state.folderName = folder.name;

      store.dispatch("template/setFolder", {
        folderId: folder.id,
        folderName: folder.name,
      });
    }


    // Edit Contract Section
    const onEdit = async () => {
      await saveEditContract(storeContractInformation).catch((err) => {
        router.replace({
          name: "Folder",
          query: {
            folderId: state.folderId,
            folderTitle: state.folderName,
          },
          params: {
            withError: err.data.message,
          },
        });
      });
    };

    // Next Page Section

    const next = async () => {
      await storeContractInformation();
      router.push({
        name: "ContractSigners",
        query: route.query,
        params: route.params,
      });
    };

    //Load contract from template and store section

    const loadContractFromTemplate = async (templateId: string) => {
      const templateInformation = (await getContractsFullInformation(
        templateId
      )) as any;

      if (templateInformation) {
        loadScreenState(templateInformation);
        await storeOtherScreen(templateInformation, templateId);
      }
    };

    const loadScreenState = (templateInformation: any) => {
      const haveBeenMappedContractFile = mapCloudFileToLocalFile(
        templateInformation.contractFiles ?? []
      );

      loadContractTitleAndPartnerName(
        "",
        templateInformation.title,
        templateInformation.contractPartnerName
      );

      if (templateInformation.contractInformation)
        loadContractDetailInformation(templateInformation);

      if (templateInformation.contractCustomFields)
        loadContractCustomField(templateInformation.contractCustomFields);

      loadContractFile(haveBeenMappedContractFile);
    };

    const storeOtherScreen = async (templateInformation: any, createdFromTemplate: string) => {
      const securityOptions = mapLocalSecurityFromCloud(templateInformation);
      const signers = mapLocalSignerFromCloudSigner(
        templateInformation.contractSigners ?? []
      );
      const viewers = mapLocalViewerFromCloudViewer(
        templateInformation.contractViewers ?? []
      );

      await Promise.all([
        store.dispatch("template/setSigners", signers),
        store.dispatch("template/setSecurity", securityOptions),
        store.dispatch("template/setViewers", viewers),
        store.dispatch(
          "template/setMessage",
          templateInformation.message ?? ""
        ),
        store.dispatch(
          "template/setCreatedFromTemplate", createdFromTemplate ?? ""
        )
      ]);
    };

    const loadContractTitleAndPartnerName = (
      id: string,
      title: string,
      partnerName: string
    ) => {
      contractBasicInfo.id = id;
      contractBasicInfo.title = title;
      contractBasicInfo.contractPartnerName = partnerName ?? "";
    };

    const loadContractDetailInformation = (contractDetailInformation: any) => {
      const {
        contractOverview,
        contractConclusionDate,
        contractEndDate,
        contractStartDate,
        isAutomaticallyUpdated = "null",
        controlNumber,
        freeText,
        terminationNoticePeriod,
        transactionAmount,
      } = contractDetailInformation.contractInformation;

      contractDetailInfo.contractOverview = contractOverview ?? "";

      contractDetailInfo.contractConclusionDate = parseContractDate(
        contractConclusionDate
      ) as any;
      contractDetailInfo.contractEndDate = parseContractDate(
        contractEndDate
      ) as any;
      contractDetailInfo.contractStartDate = parseContractDate(
        contractStartDate
      ) as any;

      contractDetailInfo.isAutomaticallyUpdated = isAutomaticallyUpdated;
      contractDetailInfo.controlNumber = controlNumber;
      contractDetailInfo.freeText = freeText;
      contractDetailInfo.terminationNoticePeriod = terminationNoticePeriod;
      contractDetailInfo.transactionAmount = transactionAmount;
    };

    const loadContractCustomField = (customFields: any) => {
      state.customFields = [...customFields];
    };

    const loadContractFile = (files: any) => {
      state.files = [...files];
    };

    const isContractHasBeenStored = (stateOfContract: any) => {
      return stateOfContract.title != "" && stateOfContract.files.length != 0
        ? true
        : false;
    };

    const accountInfo = store.getters["header/getAccountInfo"];

    const initialContractName = (name: string) => {
      contractBasicInfo.title = name.slice(0, -4);
    };

    onMounted(async () => {
      let stateOfContract = store.getters["template/getTemplateInfo"];
      if (accountInfo.planType == 0) {
        router.push({
          name: "PricingPlan",
        });
      }

      editState.canDelete = route.params.canDelete === "false" ? false : true;
      editState.isEdit = route.query.isEdit === "true";
      editState.onlyInformation = route.query.onlyInformation === "true";
      if (editState.isEdit) {
        if (stateOfContract.title === "" && route.query.contractId) {
          await loadContractFromContractId(
            route.query.contractId as string
          ).catch((err) => {
            router.replace({
              name: "Folder",
              query: {
                folderId: state.folderId,
              },
              params: {
                withError: err.data.message,
              },
            });
          });
          stateOfContract = store.getters["template/getTemplateInfo"];
        }

        state.progressItems = editProgressBarState();
        const folderPath = buildContractEditBreadScrumPath(
          stateOfContract.folderId ?? "",
          stateOfContract.folderName ?? ""
        );
        state.breadScrumPaths = [
          { text: stateOfContract.folderName, to: folderPath },
          { text: stateOfContract.title, to: "#" },
        ] as never;
      }
      const {
        id,
        title,
        contractPartnerName,
        contractCustomFields,
        files,
        folderId,
        folderName,
        stepError,
      } = stateOfContract;

      if (isContractHasBeenStored(stateOfContract)) {
        loadContractTitleAndPartnerName(id ?? "", title, contractPartnerName);
        loadContractDetailInformation(stateOfContract);
        loadContractCustomField(contractCustomFields);
        loadContractFile(files);
      }
      state.folderId = folderId;
      state.folderName = folderName;
      state.stepError = stepError;
      state.contractStatus = route.params.status as string;
    });

    watch(
      () => accountInfo.planType,
      () => {
        if (accountInfo.planType == 0) {
          router.push({
            name: "PricingPlan",
          });
        }
      }
    );

    return {
      ...toRefs(state),
      ...toRefs(contractBasicInfo),
      ...toRefs(contractDetailInfo),
      ...toRefs(editState),
      isMobile,
      next,
      onSave,
      deleteDraftContract,
      onEdit,
      loadContractFromTemplate,
      resetState,

      storeContractInformation,
      setError,
      backToFolder,

      onChoose,
      accountInfo,
      initialContractName,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!to.path.includes("/contract")) {
      this.resetState();
    }
    next();
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    addCustomField(): void {
      this.customFields.push({
        title: "",
        value: "",
      });
    },
    removeCustomField(index: number): void {
      if (this.customFields.length > 0) {
        this.customFields.splice(index, 1);
      }
    },
    onSelectedFileAt(index: number) {
      if (index >= 0 || index < this.files.length) {
        this.selectedIndex = index;
      }
    },
  },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c7d9bb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", {
      class: _normalizeClass(["tab-header", { 'page-header': _ctx.isPageHeader }])
    }, [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabTags, (tab, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: _normalizeClass(["tab", {
          'is-active': index === _ctx.currentTabIndex,
          'tab-distance': index != 0,
          'text-grey': index !== _ctx.currentTabIndex,
        }]),
            onClick: ($event: any) => (_ctx.setCurrentTab(index))
          }, _toDisplayString(tab.name) + " " + _toDisplayString(tab.total ? `(${tab.total})` : ""), 11, _hoisted_2))
        }), 128))
      ])
    ], 2),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 64))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a7029490"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_ctx.popupState)
    ? (_openBlock(), _createBlock(_component_Popup, {
        key: 0,
        onOnClickOutsidePopup: _ctx.hidePopup,
        onOnHide: _ctx.hidePopup,
        title: _ctx.$t('sign.returnToContract')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "message",
              innerHTML: _ctx.$t('contract.cancelMessage')
            }, null, 8, _hoisted_2),
            _createVNode(_component_FlatButton, {
              class: "mt-5 w-full",
              text: _ctx.$t('sign.verifyButton'),
              onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onCancel')))
            }, null, 8, ["text"])
          ])
        ]),
        _: 1
      }, 8, ["onOnClickOutsidePopup", "onOnHide", "title"]))
    : _createCommentVNode("", true)
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "template-picker" }
const _hoisted_2 = { class: "message mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("common.pickTemplate")), 1),
    _createVNode(_component_TextButton, {
      class: "button",
      text: _ctx.$t('common.select'),
      icon: require('icons/contract.svg'),
      onOnClick: _ctx.onClickUpload
    }, null, 8, ["text", "icon", "onOnClick"])
  ]))
}
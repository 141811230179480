import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fbcfb1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 2,
  class: "last-link long-name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'link-row': _ctx.isRouterLink, 'folder-row': !_ctx.isRouterLink })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paths, (path, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "link-item"
      }, [
        (index != 0 && index != _ctx.linkLength)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require('icons/chervon-black.svg'),
              class: "icon-chervon"
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        (_ctx.isRouterLink == true && index != _ctx.linkLength - 1)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: path.to,
              class: _normalizeClass(["long-name", { 'last-link': index == _ctx.linkLength - 1 }])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(path.text), 1)
              ]),
              _: 2
            }, 1032, ["to", "class"]))
          : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(path.text), 1))
      ]))
    }), 128))
  ], 2))
}

import { defineComponent } from "@vue/runtime-core";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";

export default defineComponent({
  name: "EditBottomNavBar",
  components: {
    TextButton,
    FlatButton,
  },
  props: {
    onNext: { type: Function, default: null },
    onBack: { type: Function, default: null },
    onEdit: { type: Function, default: null },
  },
});


import { defineComponent, reactive, toRefs } from "vue";
import { mixin as VueClickAway } from "vue3-click-away";

export default defineComponent({
  name: "FolderPath",
  emits: ["clickRootPath", "clickFolderPath"],
  props: {
    groupPath: { type: Array, default: [] as Array<never> },
    isTemplate: { type: Boolean, default: false }
  },
  setup() {
    const state = reactive({
      isShowListFolder: false,
    });
    return {
      ...toRefs(state),
    };
  },
  mixins: [VueClickAway],
  methods: {
    clickRootPath(): void {
      this.$emit("clickRootPath");
      this.isShowListFolder = false;
    },
    clickFolderPath(folder: never): void {
      this.$emit("clickFolderPath", folder);
      this.isShowListFolder = false;
    },
  },
});

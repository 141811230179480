
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";
import Tabs from "@/components/atomics/Tabs/Tabs.vue";
import FolderPath from "@/components/atomics/FolderPath.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { RepositoryFactory, ContractRepository } from "@/lib/https";
import { getListFolderFromPath } from "@/lib/utility/common";
import Popup from "@/components/popups/Popup.vue";
import { useStore, mapMutations } from "vuex";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "ContractTemplateSelectPopup",
  components: {
    Tabs,
    ValidationTextField,
    FlatButton,
    TextButton,
    FolderPath,
    Popup,
  },
  props: {
    isMobile: { type: Boolean, default: false },
  },
  emits: ["onDelete", "onSelectFile"],
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const state = reactive({
      arrFolderName: [] as any[],
      arrFolderId: [] as any[],
      currentFolderlevel: 0,
      folderLinks: [] as any[],
      customTemplateList: {},
      builtinTemplateList: {},
      groupPath: [] as any[],
      currentTabIndex: 0,
      tabTags: [
        { name: t("template.custom"), total: 0 },
        { name: t("template.buildin"), total: 0 },
      ],
    });
    let delay: ReturnType<typeof setTimeout>;
    const searchKeywordTemplate = ref("");
    const searchKeywordTemplateTextField = ref("");
    const { getCustomTemplate, getBuiltinTemplate } =
      RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

    const clickRootPath = async () => {
      state.groupPath = [];
      const payload = {
        isTemplateFolder: "true",
        keyword: "",
        folderId: "",
      };
      const customTemplateList = await getCustomTemplate(payload);
      if (customTemplateList) {
        state.customTemplateList = customTemplateList;
      }
    };

    const clickFolderPath = async (folder: { id: string }) => {
      const payload = {
        isTemplateFolder: "true",
        keyword: "",
        folderId: folder.id,
      };
      const customTemplateList = await getCustomTemplate(payload);
      if (customTemplateList) {
        state.groupPath = getListFolderFromPath(
          customTemplateList.data.folderPath
        );
        state.customTemplateList = customTemplateList;
      }
    };

    watch(
      () => store.state.popup.SelectTemplate,
      async (val) => {
        if (val) {
          const payload = {
            isTemplateFolder: "true",
            keyword: "",
            folderId: "",
          };

          // custom template list
          const customTemplateList = await getCustomTemplate(payload);
          if (customTemplateList) {
            state.customTemplateList = customTemplateList;
            state.currentFolderlevel = 0;
            state.arrFolderName = [];
            state.arrFolderId = [];
          }

          // builtin Template List
          const builtinTemplateList = await getBuiltinTemplate({});
          if (builtinTemplateList) {
            state.builtinTemplateList = builtinTemplateList;
          }
        }
      }
    );
    // onMounted(async () => {});

    /**
     * Enter search
     */
    const templateSearch = (keyword: string) => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        searchKeywordTemplate.value = keyword;
        const payload = {
          isTemplateFolder: "true",
          keyword: searchKeywordTemplate.value,
          folderId: "",
        };
        const customTemplateList = await getCustomTemplate(payload);
        if (customTemplateList) {
          state.customTemplateList = customTemplateList;
          state.arrFolderName = [];
          state.arrFolderId = [];
          state.currentFolderlevel = 0;
        }
      }, 300);
    };

    /**
     * Enter folder
     */
    const clickFolder = async (item: any) => {
      const payload = {
        isTemplateFolder: "true",
        keyword: "",
        folderId: item.id,
      };
      const customTemplateList = await getCustomTemplate(payload);
      if (customTemplateList) {
        state.groupPath = getListFolderFromPath(
          customTemplateList.data.folderPath
        );
        state.customTemplateList = customTemplateList;
      }
    };

    return {
      ...toRefs(state),
      searchKeywordTemplate,
      templateSearch,
      clickFolder,
      clickRootPath,
      getListFolderFromPath,
      clickFolderPath,
      popupState: computed(() => store.state.popup.SelectTemplate),
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    hidePopup(): void {
      this.unsetPopup("SelectTemplate");
    },

    confirmDelete(): void {
      this.$emit("onDelete");
    },
    navigateToCreateTemplate(): void {
      this.$router.push({ name: "TemplateFileAndInfo" });
      this.unsetPopup("SelectTemplate");
    },
    selectFile(id: string): void {
      this.$emit("onSelectFile", id);
      this.unsetPopup("SelectTemplate");
    },
  },
});

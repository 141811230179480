import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0940cb8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "popup-body"
}
const _hoisted_2 = { class: "main-content" }
const _hoisted_3 = { class: "table-item" }
const _hoisted_4 = { class: "list-item" }
const _hoisted_5 = ["onMouseover", "onMouseleave"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "item-name long-name" }
const _hoisted_9 = {
  key: 0,
  class: "thin-divider"
}
const _hoisted_10 = ["onMouseover", "onMouseleave"]
const _hoisted_11 = { class: "item-leading" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "item-name long-name" }
const _hoisted_14 = {
  key: 0,
  class: "thin-divider"
}
const _hoisted_15 = {
  key: 1,
  class: "main-content template-content popup-body"
}
const _hoisted_16 = { class: "table-item border-none" }
const _hoisted_17 = { class: "item" }
const _hoisted_18 = { class: "item-leading" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "item-name long-name" }
const _hoisted_21 = {
  key: 0,
  class: "thin-divider w-over"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_FolderPath = _resolveComponent("FolderPath")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_ctx.popupState)
    ? (_openBlock(), _createBlock(_component_Popup, {
        key: 0,
        onOnClickOutsidePopup: _ctx.hidePopup,
        onOnHide: _ctx.hidePopup,
        "has-header-devider": ""
      }, {
        title: _withCtx(() => [
          _createVNode(_component_Tabs, {
            "is-page-header": false,
            "tab-tags": _ctx.tabTags,
            currentTabIndex: _ctx.currentTabIndex,
            "onUpdate:currentTabIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTabIndex) = $event))
          }, null, 8, ["tab-tags", "currentTabIndex"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["popup-container", { 'mb-3': _ctx.currentTabIndex == 1 }])
          }, [
            (_ctx.currentTabIndex == 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_ValidationTextField, {
                    name: 'search-input',
                    "keep-error-space": false,
                    placeholder: _ctx.$t('template.searchHint'),
                    "suffix-icon": require('icons/close-black.svg'),
                    "prefix-icon": require('icons/search-placeholder.svg'),
                    class: "search-bar mb-3",
                    value: _ctx.searchKeywordTemplateTextField,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchKeywordTemplateTextField) = $event)),
                    onOnInput: _ctx.templateSearch
                  }, null, 8, ["placeholder", "suffix-icon", "prefix-icon", "value", "onOnInput"]),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_FolderPath, {
                      class: "folder-breadscrum",
                      "group-path": _ctx.groupPath,
                      onClickRootPath: _ctx.clickRootPath,
                      onClickFolderPath: _ctx.clickFolderPath
                    }, null, 8, ["group-path", "onClickRootPath", "onClickFolderPath"]),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customTemplateList?.data?.folders
                    ?.data, (item, index) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                            _createElementVNode("div", {
                              class: "item",
                              onMouseover: ($event: any) => (item.mouseOver = true),
                              onMouseleave: ($event: any) => (item.mouseOver = false)
                            }, [
                              _createElementVNode("div", {
                                class: "item-leading item-folder",
                                onClick: ($event: any) => (_ctx.clickFolder(item))
                              }, [
                                _createElementVNode("img", {
                                  src: require('icons/primary-folder.svg'),
                                  alt: "file icon",
                                  class: "item-icon"
                                }, null, 8, _hoisted_7),
                                _createElementVNode("p", _hoisted_8, _toDisplayString(item.name), 1)
                              ], 8, _hoisted_6)
                            ], 40, _hoisted_5),
                            (
                      index !== _ctx.customTemplateList?.data?.folders.length - 1
                    )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customTemplateList?.data?.contracts
                    ?.data, (item, index) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                            _createElementVNode("div", {
                              class: "item",
                              onMouseover: ($event: any) => (item.mouseOver = true),
                              onMouseleave: ($event: any) => (item.mouseOver = false)
                            }, [
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("img", {
                                  src: require('icons/report_orange.svg'),
                                  alt: "file icon",
                                  class: "item-icon"
                                }, null, 8, _hoisted_12),
                                _createElementVNode("p", _hoisted_13, _toDisplayString(item.title), 1)
                              ]),
                              (item.mouseOver || _ctx.isMobile)
                                ? (_openBlock(), _createBlock(_component_FlatButton, {
                                    key: 0,
                                    text: _ctx.$t('contract.chooseTemplate'),
                                    class: "item-button",
                                    onOnClick: ($event: any) => (_ctx.selectFile(item.id))
                                  }, null, 8, ["text", "onOnClick"]))
                                : _createCommentVNode("", true)
                            ], 40, _hoisted_10),
                            (
                      index !== _ctx.customTemplateList?.data?.contracts.length - 1
                    )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ])
                    ]),
                    _createVNode(_component_TextButton, {
                      text: _ctx.$t('contract.popupAddTemplate'),
                      icon: require('icons/add.svg'),
                      class: "add-template-btn",
                      onOnClick: _ctx.navigateToCreateTemplate
                    }, null, 8, ["text", "icon", "onOnClick"])
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.builtinTemplateList?.data?.data, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("img", {
                              src: require('icons/digitalsign-template.svg'),
                              alt: "file icon",
                              class: "item-icon"
                            }, null, 8, _hoisted_19),
                            _createElementVNode("p", _hoisted_20, _toDisplayString(item.title), 1)
                          ]),
                          _createVNode(_component_FlatButton, {
                            text: _ctx.$t('contract.chooseTemplate'),
                            class: "item-button",
                            onOnClick: ($event: any) => (_ctx.selectFile(item.id))
                          }, null, 8, ["text", "onOnClick"])
                        ]),
                        (index !== _ctx.builtinTemplateList?.data?.data.length - 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_21))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ])
                ]))
          ], 2)
        ]),
        _: 1
      }, 8, ["onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}

import { defineComponent, computed } from "vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import Popup from "@/components/popups/Popup.vue";
import { useStore, mapMutations } from "vuex";

export default defineComponent({
  name: "EditCancelPopup",
  components: {
    Popup,
    FlatButton,
  },
  props: {
    isMobile: { type: Boolean, default: false },
  },
  emits: ["onCancel"],
  setup() {
    const store = useStore();

    return {
      popupState: computed(() => store.state.popup.ContractEditCancel),
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    hidePopup(): void {
      this.unsetPopup("ContractEditCancel");
    },
  },
});


import { ref, defineComponent, reactive, toRefs, watch } from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import { FileInfo } from "@/models/contracts/FileInfo";

import { getFileExtension } from "@/lib/utility/common";
import { mapMutations } from "vuex";

export default defineComponent({
  name: "FileUpload",
  components: {
    TextButton,
    FlatButton,
  },
  props: {
    files: { type: Array, default: () => [] },
    readonly: { type: Boolean, default: false },
    fileType: { type: String, default: "pdf" },
  },
  emits: ["update:files", "selectedAt", "initialContractName"],
  setup(props) {
    const state = reactive({
      input: ref(null) as any,
      maxFiles: 5,
      selectedIdx: 0,
      fileList: props.files as any[],
      fileExtension: ".pdf,.PDF",
    });

    watch(
      () => props.files,
      (val) => {
        state.fileList = val;
      }
    );

    return {
      ...toRefs(state),
    };
  },

  methods: {
    ...mapMutations("notification", ["showErrorNotification"]),
    onClickUpload() {
      this.input.click();
    },
    resetImageUploader() {
      this.input.value = "";
    },
    setFiles(e: any) {
      if (this.fileList.length >= this.maxFiles) return;

      const uploadFiles = e.target.files || e.dataTransfer.files;
      if (this.fileList.length + uploadFiles.length > this.maxFiles) {
        this.showErrorNotification(
          this.$t("errors.errorMaxFiles", { maxFiles: this.maxFiles })
        );
        return;
      }

      const remainSlot = this.maxFiles - this.fileList.length;
      const numFilesUpload =
        uploadFiles.length < remainSlot ? uploadFiles.length : remainSlot;

      for (let i = 0; i < numFilesUpload; i++) {
        const fileName = getFileExtension(uploadFiles[i].name);
        if (!this.fileExtension.includes(fileName)) break;
        if (uploadFiles[i].size > 33554432) {
          this.showErrorNotification(this.$t("errors.errorOversizeFile"));
          break;
        }
        const { name, type, size } = uploadFiles[i];
        const file = new FileInfo(
          name + i,
          "",
          name,
          URL.createObjectURL(uploadFiles[i]),
          type || "docx",
          [],
          uploadFiles[i],
          size,
          1,
          1,
          1,
          0,
          0
        );
        this.fileList.push(file);
      }

      this.$emit("update:files", this.fileList);
      this.$emit("selectedAt", this.selectedIdx);
      this.$emit("initialContractName", this.fileList[0].data.name);
    },
    removeFile(index: number) {
      if (index == this.selectedIdx) {
        this.selectedIdx = 0;
        this.$emit("selectedAt", 0);
      }
      this.fileList.splice(index, 1);
      this.$emit("update:files", this.fileList);
    },
    selectItemAt(index: number, event: any) {
      if (!event.target.className.includes("icon-del")) {
        this.selectedIdx = index;
        this.$emit("selectedAt", this.selectedIdx);
      }
    },
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74c6a035"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bottom-nav-bar" }
const _hoisted_2 = { class: "nav-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_FlatButton = _resolveComponent("FlatButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TextButton, {
        enabled: _ctx.onBack != null,
        icon: require('icons/prev-arrow.svg'),
        "disabled-icon": require('icons/prev-arrow-grey.svg'),
        onOnClick: _ctx.onBack
      }, null, 8, ["enabled", "icon", "disabled-icon", "onOnClick"]),
      _createVNode(_component_TextButton, {
        enabled: _ctx.onNext != null,
        icon: require('icons/next-arrow.svg'),
        "disabled-icon": require('icons/next-arrow-grey.svg'),
        onOnClick: _ctx.onNext
      }, null, 8, ["enabled", "icon", "disabled-icon", "onOnClick"])
    ]),
    _createVNode(_component_FlatButton, {
      text: _ctx.$t('common.saveChanges'),
      class: "edit-button",
      enabled: _ctx.onEdit != null,
      onClick: _ctx.onEdit
    }, null, 8, ["text", "enabled", "onClick"])
  ]))
}